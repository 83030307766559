import { m } from 'framer-motion';
// next
// @mui
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// layouts
import Layout from '../layouts';
// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { useRouter } from 'next/router';
import { PageNotFoundIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(15),
  },
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

Page404.getLayout = function getLayout(page) {
  return <Layout variant="logoOnly">{page}</Layout>;
};

// ----------------------------------------------------------------------

export default function Page404() {
  const router = useRouter();

  return (
    <Page title="404 Không tìm thấy trang" description="404 Không tìm thấy trang web với địa chỉ url bạn nhập" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Xin lỗi, không tìm thấy trang!
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Xin lỗi, chúng tôi không thể tìm thấy trang. Có lẽ, bạn nhập sai đường dẫn gì đó chăng, bạn giúp kiểm tra lại xem nhé.
            </Typography>
            <m.div variants={varBounce().in}>
              <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>
            <Button size="large" variant="contained" onClick={() => router.back()}>
              Quay trở về
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
